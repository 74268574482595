<template>
  <div class="news-page">
    <Nav />
    <div class="news-wrap">
      <div class="news-top">
        <h1>新闻中心</h1>
        <svg
          class="arrow"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
            fill="white"
            fill-opacity="0.7"
          />
        </svg>
      </div>
      <div class="news-list">
        <div class="custom-vant-tab tab-wrap">
          <van-tabs type="card" v-model="activeName" @click="onClick">
            <van-tab title="全部"></van-tab>
            <van-tab title="产品"></van-tab>
            <van-tab title="新闻稿"></van-tab>
            <van-tab title="公司"></van-tab>
          </van-tabs>
        </div>

        <div class="news-card">
          <div
            v-for="item in newsList"
            :key="item.uuid"
            class="news"
            @click="goDetail(item.uuid)"
          >
            <img :src="item.cover_image" alt="" />
            <div class="about">
              <div class="about-top">
                <span class="tag">{{ item.tags[0] }}</span>
                <span class="date">{{ formatDate(item.news_time) }}</span>
              </div>
              <div class="brief">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pagination-box custom-pagination">
        <van-pagination
          @change="pageChange"
          v-model="currentPage"
          :total-items="total"
          :items-per-page="page"
        />
        <div class="common-bottom-empty"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../../component/nav.vue";
import request from "../../utils/request";
import dayjs from "dayjs"; // 导入封装好的axios实例
import { Pagination, Tab, Tabs } from "vant";

export default {
  name: "NEWS",
  components: {
    Nav,
    [Pagination.name]: Pagination,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      activeName: 0,
      newsList: [], // 将newsList初始化为空数组
      currentPage: 1,
      page: 10,
      total: 24,
      tag: "all",
    };
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format("YYYY.MM.DD");
    },
    // 获取新闻列表数据
    fetchNewsList() {
      request
        .get("/news/list", {
          params: {
            page_size: this.page,
            page: this.currentPage,
            tag: this.tag,
          },
        }) // 假设接口地址为 /news
        .then((response) => {
          this.newsList = response.data; // 将接口返回的数据赋值给newsList
          this.total = response.count;
        })
        .catch((error) => {
          console.error("Error fetching news list:", error);
        });
    },
    pageChange() {
      // console.log(this.currentPage)
      this.fetchNewsList();
    },
    goDetail(newsId) {
      // 传递新闻ID到新闻详情页面
      this.$router.push({ name: "newsDetail", query: { id: newsId } });
    },
    onClick(name, title) {
      console.log(name, title);
      this.currentPage = 1;
      if (title === "全部") {
        this.tag = "all";
      } else {
        this.tag = title;
      }
      this.fetchNewsList();
    },
  },
  created() {
    // 在组件创建时调用fetchNewsList方法获取新闻列表数据
    this.fetchNewsList();
  },
};
</script>

<style lang="less" scoped>
.news-page {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: #cdd7de;
  .news-wrap {
    position: relative;
    padding-top: 96px;
    width: 100%;
  }
  .news-top {
    padding: 0 32px;
    h1 {
      margin-bottom: 8px;
      color: #212027;
      font-size: 40px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 48px */
    }
    .arrow {
      margin-bottom: 32px;
    }
  }
  .news-list {
    padding: 40px 0 40px 0;
    width: 100%;
    min-height: 500px;
    border-radius: 40px 40px 0px 0px;
    border-top: 2px solid #fff;
    background: linear-gradient(180deg, #f0f5f7 0%, #fff 87.52%);
    .tab-wrap {
      margin-bottom: 32px;
      h1 {
        margin-bottom: 8px;
        color: var(--, #212027);
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
      }
      h2 {
        color: #212027;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 33.6px */
      }
      h3 {
        margin-bottom: 16px;
        color: var(--, #212027);
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 28.8px */
      }
      p {
        margin-bottom: 32px;
        color: var(----, #535359);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
      }
      .quote {
        margin-bottom: 32px;
        padding: 16px;
        border-radius: 24px;
        background: #f0f3f4;
        p {
          margin-bottom: 0;
          span {
            margin: 0 4px 0 6px;
            display: inline-block;
            width: 4px;
            height: 4px;
            background: #fc0;
            vertical-align: middle;
          }
        }
        .p-li {
          margin-bottom: 16px;
          b {
            color: #212027;
            font-size: 15px;
            font-weight: 600;
            text-decoration-line: underline;
          }
        }
        .spec01 {
          color: #212027;
          font-weight: 600;
          line-height: 160%; /* 22.4px */
        }
        .end-box {
          display: flex;
          justify-content: flex-end;
        }
        h4 {
          margin-bottom: 16px;
          color: #212027;
          /* 小标题 */
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 21.6px */
        }
      }
      .story {
        padding: 35px 32px;

        img {
          margin-bottom: 32px;
          display: block;
          width: 100%;
          object-fit: cover;
        }
        .spec {
          color: var(---, #fabf00);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 19.2px */
          text-decoration-line: underline;
        }
        .man-about {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          .head {
            margin-right: 10px;
            img {
              margin-bottom: 0;
              display: block;
              width: 60px;
              height: 60px;
            }
          }
          .man-title {
            color: var(---, #fabf00);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .man-tag {
            color: var(--, #212027);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
        .en-doc {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
          padding: 12px;
          border-radius: 12px;
          border: 1px solid var(--, #fff);
          background: var(--, #fff);

          /* 卡片投影 */
          box-shadow: 0px 0px 16px 0px #d7d9dc;
          img {
            margin: 0;
            margin-right: 8px;
            display: block;
            width: 38px;
            height: 38px;
          }
          .doc-name {
            margin: 0;
            color: var(---, #212027);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 19.2px */
          }
        }
      }
      .duty {
        padding: 35px 32px;
        .card {
          margin-bottom: 32px;
          overflow: hidden;
          border-radius: 24px;
          border: 1px solid var(---, #fff);
          background: var(---, #fff);
          box-shadow: 0px 0px 16px 0px #d7d9dc;
          img {
            display: block;
            width: 100%;
            height: 200px;
          }
          .content {
            padding: 16px;
            h4 {
              margin-bottom: 8px;
              color: #212027;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%; /* 21.6px */
            }
            p {
              margin-bottom: 16px;
              color: #505054;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 160%; /* 22.4px */
            }
            .btn-more {
              box-sizing: border-box;
              display: flex;
              width: 160px;
              height: 40px;
              padding: 12px 24px;
              justify-content: center;
              align-items: center;
              border-radius: 40px;
              background: #fc0;
              color: #212027;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
      .join {
        .join-about {
          padding: 35px 32px;
        }
        .arrow {
          margin-bottom: 32px;
        }
        .btn-more {
          margin-bottom: 60px;
          padding: 12px 24px;
          color: #212027;
          font-size: 16px;
          font-weight: 600;
          line-height: 120%; /* 19.2px */
          border-radius: 40px;
          background: #fc0;
        }
        .advantage {
          padding: 40px 32px;
          border-radius: 40px 40px 0px 0px;
          border-top: 2px solid #fff;
          background: linear-gradient(180deg, #f0f5f7 0%, #fff 87.52%);
          .en-title {
            padding: 8px 0;
            color: #505054;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
    .news-card {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 20px;
      width: calc(100% - 40px);
      .news {
        margin-bottom: 16px;
        border: 1px solid #fff;
        width: 160px;
        border-radius: 24px;
        overflow: hidden;
        background: #fff;
        box-shadow: 0px 0px 16px 0px #d7d9dc;
      }
      img {
        display: block;
        width: 160px;
        height: 160px;
        object-fit: cover;
      }
      .about {
        padding: 10px 10px 12px 10px;
      }
      .about-top {
        margin-bottom: 8px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .tag {
          padding: 0 4px;
          height: 16px;
          background: #ffcc00;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #212027;
          border-radius: 4px;
        }
        .date {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #a9a9ac;
        }
      }
      .brief {
        font-size: 16px;
        line-height: 22px;
        color: #212027;
        font-weight: 600;
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .pagination-box {
      background: #fff;
      padding: 0 20px 40px 20px;
    }
  }
}
</style>
<style lang="less">
.news-page {
  .van-tabs__wrap {
    justify-content: center;
  }
}
</style>
